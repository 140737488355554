<template>
	<div id="login" style="position: relative;">
		<div id="logins">
			<div class="login_con">
				<div class="logo_img_wrap">
				<img class="login_con_img" :src="teachlogo2Url" />
				<div>云笔课堂</div>
			</div>
				<el-input class="input"
					:placeholder="$store.state.teachlanguagedata.EnterMobile?$store.state.teachlanguagedata.EnterMobile:'请输入账号'"
					v-model="userName"></el-input>
				<el-input class="input"
					:placeholder="$store.state.teachlanguagedata.EnterPassword?$store.state.teachlanguagedata.EnterPassword:'请输入密码'"
					v-model="passWord" show-password></el-input>
				<el-button class="login_con2 input" @click="toLogin">登陆</el-button>
			</div>
		</div>
		<div style="width:100%;font-size: 14px;position: absolute;bottom: 20px;display: flex;justify-content: center;">
			<a style="color: #FFFFFF;" href="https://www.beian.gov.cn">备案信息 {{baseName}}</a>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Login',
		data() {
			return {
				teachlogo2Url: '',
				baseName: '',
				src: '',
				userName: '',
				passWord: '',
				teachlogo1Url: ''
			}
		},
		computed: {},
		created() {},
		mounted() {
			this.toimg()
		},
		methods: {
			toimg() {
				this.$axios.post(this.$apis + "/userPlant-web/api/console/corp/queryByDomain", {
					domainHost: window.location.host
				}).then(response => {
					if (response.data.result) {
						this.src = "https://api.youcun.tech/epen-slice-upload" + response.data.result.logo2Url
						this.teachlogo2Url = require('../assets/img/02/logo3.png')
						// this.teachlogo2Url = "https://api.youcun.tech/epen-slice-upload" + response.data.result
						// 	.logo2Url
						this.teachlogo1Url = "https://api.youcun.tech/epen-slice-upload" + response.data.result
							.logo1Url
						if (response.data.result.logo1Url) {
							let login = document.querySelector("#login")
							login.style.backgroundImage = 'url(' + this.teachlogo1Url + ')'
						} else {
							let login = document.querySelector("#login")
							login.style.backgroundImage =
								'url(https://anyid-tupian.oss-cn-shanghai.aliyuncs.com/bgc.png)'
						}
						this.baseName = response.data.result.baseName
						this.$forceUpdate()
					} else {
						let login = document.querySelector("#login")
						login.style.backgroundImage =
							'url(https://anyid-tupian.oss-cn-shanghai.aliyuncs.com/bgc.png)'
						// this.teachlogo2Url = false
						this.teachlogo2Url = require('../assets/img/02/logo3.png')
						localStorage.removeItem("teachlogo2Url")
					}
				}).catch(res => {
					let login = document.querySelector("#login")
					login.style.backgroundImage = 'url(https://anyid-tupian.oss-cn-shanghai.aliyuncs.com/bgc.png)'
				})
			},
			toLogin() {
				let data = {
					'userName': this.userName,
					'passWord': this.passWord
				}
				this.$axios.post(this.$apis + "/userPlant-web/api/console/user/login", data).then(response => {
					if (response.data.msg == "成功") {
						if (response.data.result.type.indexOf('master')==-1&&response.data.result.type.indexOf('teacher')==-1&&response.data.result.type.indexOf('manager')==-1) {
							this.$message.error('你登录的账号不是老师账号')
							return
						}
						if (localStorage.length > 0) {
							for (let i = localStorage.length - 1; i >= 0; i--) {
								if (localStorage.key(i).indexOf('teach') != -1) {
									localStorage.removeItem(localStorage.key(i))
								}
							}
						}
						if (this.teachlogo2Url) {
							localStorage.setItem("teachlogo2Url", JSON.stringify(this.teachlogo2Url))
						}
						localStorage.setItem("teachlanguage", JSON.stringify(response.data.result.language ?
							response.data.result.language : "汉语"))
						localStorage.setItem("teachuserID", JSON.stringify(response.data.result.id))
						localStorage.setItem("teachuserToken", JSON.stringify(response.data.result.token))
						localStorage.setItem("teachschoolID", JSON.stringify(response.data.result.corpId))
						localStorage.setItem("teachsubjects", JSON.stringify(response.data.result.subjects))
						localStorage.setItem("teachusername", JSON.stringify(response.data.result.name))
						localStorage.setItem("teachlogin", JSON.stringify(response.data.result))
						localStorage.setItem("teachusertype", response.data.result.type)
						this.$router.push({
							path: '/',
						});
					} else {
						this.$message({
							type: 'info',
							message: response.data.msg
						});
					}
				})
			}
		}
	}
</script>
<style>
	#login {
		height: 100vh;
		width: 100%;
		overflow: hidden;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	#logins {
		height: 90vh;
		display: flex;
		align-items: center;
		justify-content: right;
	}

	.login_con {
		width: 360px;
		height: 400px;
		margin-right: 400px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #C2CDDE;
	}

	.input {
		width: 300px;
		height: 40px;
		margin-top: 45px;
	}

	.login_con2 {
		background: #19409E;
		color: #FFFFFF;
	}
	.logo_img_wrap{
		height: 100px;
		color: #19409E;
		font-size: 36px;
		font-weight: 700;
		display: flex;
		align-items: center;
	}
	.login_con_img {
		margin: 20px 40px;
		height: 70px;
	}
	.logo_img_wrap div{
		margin-top: 20px;
	}
</style>
